// extracted by mini-css-extract-plugin
export var servicesDevExpertiseCompany = "df_zk";
export var servicesDevExpertiseCompany__card = "df_zl";
export var servicesDevExpertiseCompany__emoji = "df_zn";
export var servicesDevExpertiseCompany__maxWidth140 = "df_zq";
export var servicesDevExpertiseCompany__maxWidth180 = "df_zr";
export var servicesDevExpertiseCompany__maxWidth190 = "df_zs";
export var servicesDevExpertiseCompany__maxWidth270 = "df_zt";
export var servicesDevExpertiseCompany__maxWidth310 = "df_zv";
export var servicesDevExpertiseCompany__text = "df_zp";
export var servicesDevExpertiseCompany__title = "df_zm";