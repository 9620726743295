// extracted by mini-css-extract-plugin
export var servicesTechStack__item = "f8_Qz";
export var servicesTechStack__itemWrapper = "f8_Qy";
export var servicesTechStack__name = "f8_QB";
export var servicesTechStack__name_chromedevtools = "f8_QK";
export var servicesTechStack__name_digitalocean = "f8_QH";
export var servicesTechStack__name_elasticsearch = "f8_QF";
export var servicesTechStack__name_gitlab = "f8_QJ";
export var servicesTechStack__name_googleCloudPlatform = "f8_QM";
export var servicesTechStack__name_googleDocs = "f8_QL";
export var servicesTechStack__name_googleanalytics = "f8_QC";
export var servicesTechStack__name_prometheus = "f8_QD";
export var servicesTechStack__name_ror = "f8_QG";