// extracted by mini-css-extract-plugin
export var saasBusinessesSection = "r_b8";
export var saasClientQuotesSection = "r_cp";
export var saasDevExpertiseSection = "r_cm";
export var saasDiscoverWorksSection = "r_ch";
export var saasDomainExpertiseSection = "r_cb";
export var saasLineSection = "r_b9";
export var saasProcessSection = "r_cc";
export var saasProjLogoSection = "r_cl";
export var saasRequirementSoftwareSection = "r_cg";
export var saasServicesSection = "r_b6";
export var saasSolutionSection = "r_cn";
export var saasSolutionsSection = "r_b7";
export var saasSuccessfulSection = "r_cj";
export var saasTechStackSection = "r_cd";
export var saasTechStackSection__callToAction = "r_cf";
export var saasWhyShouldSection = "r_ck";